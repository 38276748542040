import React, {useMemo} from 'react';
import {AxiosError} from 'axios';

import {Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {clientService, freelancerService} from '../../api';
import {BANNERS, CLIENT, FREELANCER} from '../../constants';
import {useAuth} from '../../hooks';
import {EBannerType, EDashboardNotification, IDashboardClient, IDashboardFreelancer} from '../../models';
import {Banner, DashboardLayout, MyCurrentMatches, MyWork, Overview, ProfileCard, showToastError} from '..';


const DashboardPage = () => {
    const {isClient, isFreelancer} = useAuth();

    const {
        data,
        // isLoading,
    } = useQuery(['dashboard'], (isClient ? clientService : freelancerService).dashboard, {
        staleTime: 15 * 60 * 1000,
        onError: (error) => showToastError(error as AxiosError)
    });

    const {
        data: client,
    } = useQuery([CLIENT], clientService.getClient, {
        enabled: isClient,
        staleTime: 15 * 60 * 1000,
    });

    const {
        data: freelancer,
    } = useQuery([FREELANCER], freelancerService.getFleelancer, {
        enabled: isFreelancer,
        staleTime: 15 * 60 * 1000,
        select: ((data) => data)
    });

    const {
        data: banners,
    } = useQuery([BANNERS], (isClient ? clientService : freelancerService).dashboardBanners, {
        staleTime: 0,
    });

    const dataClient = data as IDashboardClient;
    const dataFreelancer = data as IDashboardFreelancer;
    const user = client || freelancer;

    const hasBanner = data
        ? (
            (isClient && !dataClient.hasAnyPaymentMethodAdded) ||
            (isFreelancer && (!dataFreelancer.hasAnyPayoutMethodAdded || !dataFreelancer.stripeAccountInitiated))
        )
        : false;

    const notifications = useMemo(() => {
        const result: Array<{name?: string; type: EDashboardNotification; data?: unknown}> = [{type: EDashboardNotification.SUNSET}];

        if (!banners) return [];

        const {billingProblem, newMatch, newProposal, requestForPause, timesheetForReview, timesheetInfo} = banners;

        // show this banner only if payment method exists
        if (billingProblem) {
            if (isClient && dataClient?.hasAnyPaymentMethodAdded) {
                result.push({type: EDashboardNotification.PAYMENT_FAILED});
            } else if (isFreelancer && !banners.stripeProblem && banners.billingProblem) {
                result.push({type: EDashboardNotification.PAYMENT_FAILED});
            }
        }
        if (timesheetInfo) result.push({type: EDashboardNotification.TIMESHEET_INFO, data: timesheetInfo});
        if (newMatch) result.push({type: EDashboardNotification.NEW_MATCH});
        if (newProposal) result.push({type: EDashboardNotification.NEW_PROPOSAL});
        if (requestForPause) result.push({
            name: requestForPause,
            type: EDashboardNotification.REQUEST_ENGAGEMENT_PAUSE
        });
        if (timesheetForReview) result.push({name: timesheetForReview, type: EDashboardNotification.TIMECARD_WAITING});

        return result;
    }, [banners, dataClient?.hasAnyPaymentMethodAdded, isClient, isFreelancer]);

    return (
        <DashboardLayout
            hasBanner={hasBanner} //todo: this is important prop for layout to rebuild grid structure in case the page has banner
            notifications={notifications}
            title={user ? `Welcome, ${user.firstName}!` : ''}
        >
            <Box sx={{gridArea: 'profile'}}>
                <ProfileCard/>
            </Box>

            {
                hasBanner && (
                    <Box sx={{gridArea: 'banner'}}>
                        {isClient && !dataClient?.hasAnyPaymentMethodAdded && (
                            <Banner type={EBannerType.AddPayout}/>
                        )}

                        {isFreelancer && (
                            <>
                                {!dataFreelancer?.stripeAccountInitiated
                                    ? <Banner type={EBannerType.IntegrateStripe}/>
                                    : !dataFreelancer?.hasAnyPayoutMethodAdded
                                        ? <Banner type={EBannerType.AddPayout}/>
                                        : null
                                }
                            </>
                        )}
                    </Box>
                )
            }

            <Box sx={{gridArea: 'overview'}}>
                <Overview data={data}/>
            </Box>

            <Box sx={{gridArea: 'work', overflow: 'hidden', height: 'fit-content'}}>
                <MyWork/>
            </Box>

            <Box sx={{gridArea: 'matches', overflow: 'hidden', height: 'fit-content'}}>
                <MyCurrentMatches/>
            </Box>
        </DashboardLayout>
    );
};

export default React.memo(DashboardPage);
