import React, {useMemo} from 'react';

import {Box, Typography} from '@mui/material';

import {BILLING, RouteClientPortal, RouteFreelancerPortal} from '../../../constants';
import {useAuth} from '../../../hooks';
import {EDashboardNotification, IBannerTimeshhetData} from '../../../models';
import theme from '../../../theme';
import {BlackBorderButton} from '../../Button';
import {GradientBorderBox} from '../../index';

interface IProps {
    data?: IBannerTimeshhetData;
    name?: string;
    type: EDashboardNotification;
}

const DashboardNotification: React.FC<IProps> = ({data, name, type}) => {
    const {isClient} = useAuth();
    const getNotificationData = useMemo(() => {
        switch (type) {
            case EDashboardNotification.SUNSET:
                return {
                    title: 'IMPORTANT: The Growth Collective platform will soon sunset.',
                    icon: '/assets/images/exclamation-mark-icon.png',
                    subtitle: <>All engagements will be closed on 9/20. Final billing will occur on 9/23, and the platform will shut down on 9/27 for all users.<br/>For more information, please see the relevant email from Toptal or contact your Toptal account manager.</>
                };
            case EDashboardNotification.NEW_MATCH:
                return {
                    buttonLabel: 'Go to My Matches',
                    buttonLink: isClient ? RouteClientPortal.myMatches : RouteFreelancerPortal.myMatches,
                    icon: '/assets/images/confetti-icon.png',
                    title: 'You’ve got a new match!',
                    subtitle: isClient
                        ? 'Congratulations on your new match! Don\'t miss out—visit "My Matches" now to see who you\'ve been paired with.'
                        : 'Congratulations on your new match! Don\'t miss out—visit "My Matches" now to see who you\'ve been paired with.'
                };
            case EDashboardNotification.STRIPE_ISSUE_CONNECT:
                return {
                    buttonLabel: 'Connect Stripe',
                    buttonLink: `${RouteFreelancerPortal.profileSettings}/${BILLING}`,
                    icon: '/assets/images/exclamation-mark-icon.png',
                    title: 'Unfortunately, we couldn’t connect your Stripe account',
                    subtitle: 'Please take a moment to double-check your account information and give it another try. If you need any assistance, feel free to reach out to our support team.'
                };
            case EDashboardNotification.NEW_PROPOSAL:
                return {
                    buttonLabel: 'Go to Inbox',
                    buttonLink: isClient ? RouteClientPortal.inbox : RouteFreelancerPortal.inbox,
                    icon: '/assets/images/proposal-icon.png',
                    title: isClient
                        ? 'You’ve received a new proposal from a freelancer!'
                        : 'You’ve received a new proposal from a client!',
                    subtitle: isClient
                        ? 'A new proposal from a freelancer has been sent to you. Kindly review and either accept or reject it as soon as possible.'
                        : 'A new proposal from a client has been sent to you. Kindly review and either accept or reject it as soon as possible.'
                };
            case EDashboardNotification.PAYMENT_FAILED:
                return {
                    buttonLabel: 'Go to Billing',
                    buttonLink: isClient ? `${RouteClientPortal.profileSettings}/${BILLING}` : `${RouteFreelancerPortal.profileSettings}/${BILLING}`,
                    icon: '/assets/images/exclamation-mark-icon.png',
                    title: isClient
                        ? 'Unfortunately, the payment process has failed'
                        : 'Unfortunately, your payout account failed',
                    subtitle: 'Please take a moment to double-check your account information and give it another try. If you need any assistance, feel free to reach out to our support team.'
                };
            case EDashboardNotification.TIMECARD_WAITING:
                return {
                    buttonLabel: 'Go to My Hires',
                    buttonLink: RouteClientPortal.myHires,
                    icon: '/assets/images/alarm-clock-icon.png',
                    title: name + '’s timecard is waiting for review',
                    subtitle: 'A new timecard from a freelancer has been sent to you. Kindly review and either accept or reject it as soon as possible.'
                };
            case EDashboardNotification.REQUEST_ENGAGEMENT_PAUSE:
                return {
                    buttonLabel: 'Go to My Hires',
                    buttonLink: RouteClientPortal.myHires,
                    icon: '/assets/images/exclamation-mark-icon.png',
                    title: name + ' requests an engagement pause',
                    subtitle: name + ' requested to pause the engagement. Kindly review and either accept or reject it as soon as possible.'
                };

            case EDashboardNotification.TIMESHEET_INFO:
                const params = data as IBannerTimeshhetData || {};

                return {
                    buttonLabel: 'Submit a timesheet',
                    buttonLink: `${RouteFreelancerPortal.myWorkDetails}/${params.engagementId}/timesheets/view/${params.timesheetId}`,
                    icon: params?.overdue ? '/assets/images/exclamation-mark-icon.png' : '/assets/images/clock-red-icon.png',
                    title: params?.overdue ? 'Please submit your timesheet so you can get paid' : 'You have a timesheet due!',
                    subtitle: params?.overdue ? 'Submit your timesheet now for timely payments.' : 'Submit your timesheet now for timely payments.',
                };
            default:
                return {};
        }
    }, [data, isClient, name, type]);


    return (
        <GradientBorderBox
            error={type === EDashboardNotification.TIMESHEET_INFO && data?.overdue}
            sx={{
                'img': {
                    width: '48px',
                    height: '48px',
                    display: 'inline-block',
                    mb: '24px',
                    [theme.breakpoints.up('md')]: {
                        mb: 0
                    }
                },
                '.inner-box': {
                    textAlign: 'center',
                    [theme.breakpoints.up('md')]: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '24px',
                        textAlign: 'left'
                    },
                },
            }}
        >
            <img src={getNotificationData.icon} width="48" height="48" alt="notification icon"/>
            <Box
                sx={{
                    mb: '24px',
                    [theme.breakpoints.up('md')]: {
                        mb: 0,
                        mr: 'auto'
                    }
                }}
            >
                <Typography
                    sx={{
                        mb: '2px',
                        fontWeight: 600,
                        fontSize: '16px',
                    }}
                >
                    {getNotificationData.title}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: theme.palette.gray.main
                    }}
                >
                    {getNotificationData.subtitle}
                </Typography>
            </Box>
            {getNotificationData.buttonLabel && <BlackBorderButton
                href={getNotificationData.buttonLink}
                size="small"
                sx={{
                    flexShrink: 0,
                    width: '100%',
                    [theme.breakpoints.up('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {getNotificationData.buttonLabel}
            </BlackBorderButton>}
        </GradientBorderBox>
    );
};

export default React.memo(DashboardNotification);
